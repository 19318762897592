<template>
    <div>
        <div v-if="isLoading" class="card-deck justify-content-center align-items-center">
            <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
        </div>
        <div v-else class="site-wrapper explore-details explore-details-full-image bg-gray-06">

            <div class="card p-4 widget border-0 gallery bg-gray-06 rounded-0 mb-6">
                <div class="card-title  mb-0 font-size-md font-weight-semibold text-dark border-bottom pb-2 lh-1">
                    <div class="row">
                        <div class="col-12">
                            <table class="float-right">
                                <tr>
                                    <td>

                                        <router-link
                                            :to="{ name: 'CreateBusinessLocationStepper', params: { partnerId:partnerId }}"
                                            class="btn btn-primary pr-2 font-size-sm float-right" active-class="active"
                                            exact>
                                            <i class="fal fa-plus"></i>
                                            <span> Add branch</span>
                                        </router-link>


                                    </td>

                                </tr>
                            </table>


                            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                        </div>
                    </div>
                </div>
                <div class="card-body px-0 pr-2 pt-2 pb-2" style="max-height:500px; overflow-y:auto;overflow-x:hidden">

                    <div v-for="businessLocation in businessLocations" :key="businessLocation.partnerId"
                        class="page-content col-12 col-lg-12 mt-3 order-0 order-lg-1" style="padding: 0px 0px;">

                        <div class="store-listing-style store-listing-style-01">
                            <div class="m-2">
                                <div class="bg-white p-3">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="image-content align-items-center">
                                                <Gallery v-bind:css="'h-200 h-lg-200'"
                                                    v-bind:partner-id="businessLocation.partnerId" />
                                            </div>
                                        </div>
                                        <div class="col-md-7 media-body">

                                            <ul
                                                class="list-inline store-meta mb-2 font-size-sm align-items-center flex-wrap">
                                                <li class="list-iniline-item">
                                                    <span
                                                        class="h5 text-dark letter-spacing-25">{{businessLocation.businessName}}</span>
                                                </li>
                                                <li class="list-iniline-item">
                                                    <span class="d-inline-block mr-1"><i class="fal fa-map-marker-alt">
                                                        </i>
                                                    </span>
                                                    <a href="#" class="text-secondary text-decoration-none address">
                                                        {{businessLocation.partnerAddresses?businessLocation.partnerAddresses.grideCode:''}},
                                                        {{businessLocation.partnerAddresses?businessLocation.partnerAddresses.city.cityName:''}},
                                                        {{businessLocation.partnerAddresses?businessLocation.partnerAddresses.state.stateName:''}},
                                                        {{businessLocation.partnerAddresses?businessLocation.partnerAddresses.country.countryName:''}}
                                                    </a>
                                                </li>
                                                <li class="list-inline-item"><span
                                                        class="text-green">{{businessLocation.partnerCategory?businessLocation.partnerCategory.description:''}}</span>
                                                </li>


                                            </ul>

                                            <div class="media">

                                                <div class="media-body lh-14 font-size-sm pr-2">
                                                    {{businessLocation.description | truncate(150, '....')}}
                                                </div>

                                            </div>
                                            <div class="border-top pt-3 d-flex mt-4">

                                                <div class="ml-auto">
                                                    <span class="row">
                                                        <div class="col-6">
                                                            <button
                                                                @click="showBusinessLocation(businessLocation.partnerId)"
                                                                class="btn btn-primary  font-size-sm"><i
                                                                    class="fal fa-pencil"></i></button>
                                                        </div>
                                                        <div class="col-6">
                                                            <button @click="deleteBusinessLocation(businessLocation.partnerId)" class="btn btn-darker-light  font-size-sm "><i
                                                                    class="fal fa-trash"></i></button>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>



                    <div class="photo-gallery d-flex flex-wrap justify-content-between">
                        <NoResult v-if="businessLocations.length == 0" title="Ohh! Branch not found"
                            message="It seems we can’t find what you’re looking for."></NoResult>


                    </div>
                </div>
            </div>
        </div>


        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

        <DynamicModal v-show="showDeleteBusinessModalVisible" @close="closeDeleteBusinessModal"
            v-bind:modal-size="'modal-sm'" v-bind:hide-close-btn="true" v-bind:hide-header="true"
            v-bind:hide-footer="true">
            <template v-slot:body>
                <div>
                    <div class="card-deck justify-content-center align-items-center">
                        <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
                    </div>
                </div>
            </template>
        </DynamicModal>

    </div>
</template>
<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import ConfirmDialogue from '../Common/Modal/ConfirmDialogue'
    import NoResult from '../Common/Message/NoResult'
    import Gallery from "../Common/Lightbox/Gallery.vue"
    import DynamicModal from "../Common/Modal/DynamicModal"
    import {
        MoonLoader
    } from '@saeris/vue-spinners'
    export default {
        name: "ViewBusinessLocations",
        props: ["partnerId"],
        data: function () {
            return {
                isLoading: true,
                modalSize: "modal-lg",
                businessLocationId: null,
                showDeleteBusinessModalVisible: false
            };
        },
        components: {
            ConfirmDialogue,
            Gallery,
            NoResult,
            DynamicModal,
            MoonLoader
        },
        computed: {
            ...mapGetters("businessLocation", ["businessLocations"]),
            ...mapGetters(["errors", "isSuccess"]),
        },

        mounted() {

            this.$store.commit("setErrors", {});
            this.$store.commit("setIsSuccess", false);
            this.loadBusinessLocations();
        },

        methods: {
            ...mapActions("businessLocation", ["getBusinessLocations","sendDeleteBusinessRequest"]),
           async loadBusinessLocations(){
                await this.getBusinessLocations(this.partnerId).then(() => this.isLoading = false);
            },
            showBusinessLocation(businessLocationId) {
                 return  window.location.href="/editBusinessLocationStepper/"+businessLocationId;
            },
            async deleteBusinessLocation(partnerId) {
                if (partnerId) {
                    const ok = await this.$refs.confirmDialogue.show({
                        title: 'Delete Branch',
                        message: 'Are you sure you want to delete this branch? It cannot be undone.',
                        okButton: 'Delete Forever',
                    })
                    if (ok) {
                        this.showDeleteBusinessModal();
                        this.sendDeleteBusinessRequest(partnerId).then(() => {
                            this.closeDeleteBusinessModal();
                            if (this.isSuccess) {
                                this.$notify({
                                    group: "app",
                                    type: "success",
                                    title: "Done",
                                    text: "Business deleted successfully",
                                    duration: 10000,
                                    speed: 1000,
                                });
                             
                             window.location.reload();

                            }
                        })
                    }
                }
            },
            closeDeleteBusinessModal() {
                this.showDeleteBusinessModalVisible = false;
            },

            showDeleteBusinessModal() {
                this.showDeleteBusinessModalVisible = true;
            }

        },
    };
</script>
<style scoped>
    .delete-btn {
        background-color: #d5eae7 !important;
        color: #ff5a5f !important;
        border: 2px solid #ff5a5f !important;
        border-radius: 5px;
        text-transform: none;
        cursor: pointer;
    }
</style>