<template>
    <div>
        <div v-if="isLoading" class="card-deck justify-content-center align-items-center">
                <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
            </div>
          
          <div v-else>
              <lightbox  :cells="cells" :css="css?css:'h-200 h-lg-250'" :items="images"></lightbox>
          </div>
                   
                        
                  
              
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";

    import {
        MoonLoader
    } from '@saeris/vue-spinners'
    export default {
        name: "Gallery",
        props: ["partnerId",'css',"refresh"],
        data: function () {
            return {
                cells:4,
               isLoading: true,
               images:[]
            }
        },
         watch: {
      refresh: {
        handler: 'getRefresh'
      }
    },
        components: {
            MoonLoader
        },
         mounted() {
           this.getRefresh();
        },
        computed: {
            ...mapGetters("businessImage", ["businessImageUrls"]),
        },

        methods: {
             ...mapActions("businessImage", ["getBusinessImageUrls"]),
             async getRefresh(){
            this.images = await this.getBusinessImageUrls(this.partnerId);
            if(this.images){
                this.isLoading=false
                 this.$emit('hasimages', this.images.length >0?true:false);
            }
             }
        },
    };
</script>
