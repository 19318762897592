<template>
    <div>
        <div v-if="isLoading" class="card-deck justify-content-center align-items-center">
            <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
        </div>
        <div v-else>
            <div class="row" v-if="businessAddress">
               
                <div class="col-md-12 col-lg-12">
                    <div class="card border-0">

                        <table style="width:100%;">
                            <tr>
                                <td>
                                    <button style="color:#36beb0" class="btn  float-right btn-sm"
                                        @click="editAddress"><i class="fal fa-pencil"> </i> change</button>
                                </td>
                            </tr>
                        </table>
                        <table style="width:100%;">

                            <tr v-if="businessAddress && businessAddress.grideCode">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">Gride code:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.grideCode}}</span>
                                </td>
                            </tr>

                            <tr v-if="businessAddress && businessAddress.country">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">Country:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.country.countryName}}</span>
                                </td>
                            </tr>

                            <tr v-if="businessAddress && businessAddress.state">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">State:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.state.stateName}}</span>
                                </td>
                            </tr>
                            <tr v-if="businessAddress && businessAddress.city">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">City:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.city.cityName}}</span>
                                </td>
                            </tr>
                            <tr v-if="businessAddress && businessAddress.address">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">Address:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold"
                                        style="color:#253644">{{businessAddress.address}}</span>
                                </td>
                            </tr>
                            <tr v-if="businessAddress && businessAddress.landmark">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">Land mark:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.landmark}}</span>
                                </td>
                            </tr>

                            <tr v-if="businessAddress && businessAddress.telephone">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">Primary phone:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.telephone}}</span>&nbsp;&nbsp;
                                    <span v-if="businessAddress.isTelephoneVerified" style="color:#36beb0">
                                        (Verified)</span>
                                    <span v-if="!businessAddress.isTelephoneVerified" style="color:blue">(Not
                                        Verified)</span>
                                </td>
                            </tr>

                            <tr v-if="businessAddress && businessAddress.telephone2">
                                <td>
                                    <span style="color:rgba(37,54,68,255)">Phone number:</span>
                                </td>
                                <td>
                                    <span class="font-size-sm font-weight-semibold "
                                        style="color:#253644">{{businessAddress.telephone2}}</span>
                                </td>
                            </tr>

                        </table>

                    </div>
                </div>
                 <div class="col-md-12 col-lg-12">
                    <div class="card border-0">
                        <div v-if="isMapLoading" class="card-deck justify-content-center align-items-center">
                            <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
                        </div>
                      
                            <div id="googleMap" data-google-map="true" style="height:150px"></div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    #map {
        margin: 0 auto;
        background: gray;
    }

    .h-500 {
        height: 250px;
    }
</style>
<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import {
        MoonLoader
    } from '@saeris/vue-spinners'
    export default {
        name: "BusinessAddress",
        props: ["partnerId","refresh"],
        data: function () {
            return {
                isLoading: false,
                isMapLoading: false,
                location: {
                    latitude: 37.782685,
                    longitude: -122.411364,
                    zoom: 14
                },
            }
        },
        components: {
            MoonLoader
        },
         watch: {
            refresh:{
                  handler: 'getRefresh'
            }

        },
        mounted() {
            this.getRefresh();
        },
        computed: {
            ...mapGetters("businessAddress", ['gridecode', "businessAddress"]),
             ...mapGetters(["errors", "isSuccess"])
        },

        methods: {
            ...mapActions("businessAddress", ["partnerGridSearch", "getBusinessAddress"]),
             async getRefresh(){
                 
               this.$markers = [];
                this.isLoading= true;
                
            await this.getBusinessAddress(this.partnerId).then(async () => {
                this.isLoading = false;
                if(this.isSuccess){
                if (this.businessAddress) {
                          this.isMapLoading= true;
                          
                            await this.partnerGridSearch({
                                gridCode: this.businessAddress.grideCode,
                                countryCode: this.businessAddress.country.countryCode
                            }).then(async() => {
                                this.isMapLoading = false;
                                if (this.gridecode) {
                                    this.location.latitude = this.gridecode.latitude;
                                    this.location.longitude = this.gridecode.longitude;
                                }
                                await this.displayMap(this.location);


                            });

                        }else{
                                await this.$router.push({name: "CreateBussinessAddressStepper",
                                                    params: { partnerId: this.partnerId } }).catch(() => {})
                        }
                }
            });
            },
            editAddress() {
                this.$emit('triggereditaddress', true);
            },
            displayMap(location) {
                this.$map = new window.google.maps.Map(document.getElementById('googleMap'), {
                    center: new window.google.maps.LatLng(location.latitude, location.longitude),
                    zoom: this.location.zoom,
                    disableDefaultUI: true,
                });

                this.makeMarker(location.latitude, location.longitude);
            },
            makeMarker(latitude, longitude) {
                return new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(latitude, longitude),
                    icon: null,
                    map: this.$map,
                    title: null,
                });
            }
        },
    };
</script>