<template>
    <div>


        <div class="site-wrapper explore-details explore-details-full-image bg-gray-06">
            <div class="card p-4 widget border-0 gallery bg-gray-06 rounded-0 mb-6">
                <div
                    class="card-title  mb-0 font-size-md font-weight-semibold text-dark text-uppercase border-bottom pb-2 lh-1">

                    <table class="float-right">
                        <tr>
                            <td>
                                <button type="button" @click="triggerUploadImage"
                                    class="btn btn-primary font-size-sm float-left">
                                    <i class="fal fa-plus"></i> Add image
                                </button>

                            </td>
                            <td>
                                <button v-show="triggerHasImages" type="button" @click="triggerUploadImage"
                                    class="btn btn-darker-light pr-1 font-size-sm float-right">
                                    <span> <i class="fal fa-trash"></i> Remove all </span>
                                </button>

                            </td>
                        </tr>
                    </table>

                </div>
                <div class="card-body p-2">

                    <Gallery v-bind:css="'h-200 h-lg-250'" v-on:hasimages="hasImages" v-bind:refresh="refresh" v-bind:partner-id="partnerId" />

                </div>


            </div>
        </div>


    </div>
</template>

<script>
    import Gallery from "../../Common/Lightbox/Gallery.vue"
    export default {
        name: "BusinessImages",
        props: ["partnerId",'refresh'],
        data: function () {
            return {
                triggerHasImages:false
            }
        },
        components: {
            Gallery
        },
        mounted() {

        },
        computed: {

        },

        methods: {
            triggerUploadImage() {
                this.$emit('triggeruploadimage', true);
            },
            hasImages(bool){
                this.triggerHasImages=bool;
            }
        },
    };
</script>