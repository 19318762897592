<template>
  <!-- #site-wrapper start -->
  <div v-if="user" id="site-wrapper" class="site-wrapper panel site-wrapper explore-haft-map explore-haft-map-list">
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">

        <LeftSideBar />

        <div class="page-container">
          <div class="container-fluid">
          
              <ShowMyBusiness v-bind:refresh="refresh" />

          </div>
        </div>
      </div>

    </div>
     <FooterBar />
    <!-- #wrapper-content end -->
  </div>

  
  <div v-else>
    <LoadingScreen />
  </div>

 
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import ShowMyBusiness from "../MyBusiness/ShowMyBusiness";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  export default {
    name: "MyBusiness",
  data: function () {
    return {
      refresh:false
    };
  },
    components: {
      NavBar,
      LeftSideBar,
      ShowMyBusiness,
      FooterBar,
      LoadingScreen
    },
    computed: {
      ...mapGetters("auth", ["user"])
    },

    mounted() {

    },

    methods: {
      ...mapActions("auth")
    },
    beforeRouteEnter (to, from, next) { 
    next(vm => { 
        vm.refresh=true;
        next();
    }) 
  }    
    
  };
</script>