<template>
    <div>

        <div v-if="isLoading" class="card-deck justify-content-center align-items-center">
            <MoonLoader :loading="true" :color="'#36beb0'" :size="60" :sizeUnit="'px'" />
        </div>
        <div v-else>
            <div v-if="currentBusiness" class="store-listing-item">
                <div class="d-flex align-items-center flex-wrap flex-md-nowrap  py-4 py-md-0">
                    <div class="box store media align-items-stretch py-4">

                        <div class="store-image">
                            <div class="card  box-shadow-hover p-1"
                                style="border:1px solid #CCCCCC !important;max-height:150px">
                                <img :src="currentBusiness.logo" class="p-1" style="height:100%" />
                            </div>
                        </div>
                        <div class="media-body px-0 pt-4 pt-md-0">
                            <div class="font-size-lg font-weight-semibold text-dark d-inline-block mb-0 lh-1"><span
                                    class="letter-spacing-25"
                                    style="color:#253644">{{currentBusiness.businessName}}</span>
                            </div>

                            <div class="pt-1 d-flex">
                                <span class="d-inline-block mr-1"><i class="fal fa-globe"> </i>
                                </span>
                                <div class="text-secondary pr-3 text-decoration-none address">
                                    {{currentBusiness.businessEmail}}</div>
                            </div>

                            <div class="pt-1 d-flex">
                                <span class="d-inline-block mr-1"><i class="fal fa-envelope"> </i>
                                </span>
                                <div class="text-secondary pr-3 text-decoration-none address">
                                    {{currentBusiness.website}}</div>
                            </div>
                            <div class="pt-1 d-flex">
                                <span class="d-inline-block mr-1"><i class="fal fa-list-alt"> </i>
                                </span>
                                <div class="text-secondary pr-3 text-decoration-none address text-green">
                                    {{currentBusiness.partnerCategory?currentBusiness.partnerCategory.description:''}}
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <div class="mb-4">
                    <div class="pt-4 font-size-lg font-weight-semibold text-dark mb-2 lh-1"><span
                            class="letter-spacing-25" style="color:#253644">Description</span>
                    </div>

                    <div class="pt-2">

                        <div class="text-secondary pr-3 text-decoration-none address">
                            {{currentBusiness.description | truncate(500, '....')}}</div>
                    </div>
                </div>

            </div>
        </div>

        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

   <DynamicModal
      v-show="showDeleteBusinessModalVisible"
      @close="closeDeleteBusinessModal"
      v-bind:modal-size="'modal-sm'"
      v-bind:hide-close-btn="true"
      v-bind:hide-header="true"
      v-bind:hide-footer="true"
    >
      <template v-slot:body>
        <div>
          <div class="card-deck justify-content-center align-items-center">
            <MoonLoader
              :loading="true"
              :color="'#36beb0'"
              :size="60"
              :sizeUnit="'px'"
            />
          </div>
        </div>
      </template>
    </DynamicModal>
    </div>
</template>
<script>
    import {
        MoonLoader
    } from '@saeris/vue-spinners'
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import ConfirmDialogue from '../../Common/Modal/ConfirmDialogue'
    import DynamicModal from "../../Common/Modal/DynamicModal";
    export default {
        name: "BusinessDetail",
        props: ["partnerId", "triggerDeleteBusiness",'refresh'],
        data: function () {
            return {
                isLoading: false,
                showDeleteBusinessModalVisible:false
            };
        },
        components: {
            MoonLoader,
            ConfirmDialogue,
            DynamicModal
        },
        watch: {
            triggerDeleteBusiness: {
                handler: 'getTriggerDeleteBusiness'
            },
            refresh:{
                  handler: 'getRefresh'
            }

        },
        computed: {
            ...mapGetters("auth", ["user"]),
            ...mapGetters("business", ['currentBusiness']),
            ...mapGetters(["errors", "isSuccess"]),
        },
        mounted() {
            this.getTriggerDeleteBusiness(this.triggerDeleteBusiness);
            this.$store.commit("setErrors", {});
            this.$store.commit("setIsSuccess", false);
          
            this.$emit('loading', true);
            this.getRefresh();
        },
        methods: {
            ...mapActions("business", ["getPartnerBusiness","sendDeleteBusinessRequest"]),
            async getRefresh(){
                this.isLoading = true;
            await  this.getPartnerBusiness(this.partnerId).then(() => {
                this.isLoading = false;
                this.$emit('loading', false);
            });
            },
            async getTriggerDeleteBusiness(el) {
                if (el) {
                    const ok = await this.$refs.confirmDialogue.show({
                        title: 'Delete Business',
                        message: 'Are you sure you want to delete this business? It cannot be undone.',
                        okButton: 'Delete Forever',
                    })
                    if (ok) {
                        this.showDeleteBusinessModal();
                        this.sendDeleteBusinessRequest(this.partnerId).then(()=>{
                            this.closeDeleteBusinessModal();
                              if (this.isSuccess) {
                                        this.$notify({
                                            group: "app",
                                            type: "success",
                                            title: "Done",
                                            text: "Business deleted successfully",
                                            duration: 10000,
                                            speed: 1000,
                                    });
                                        this.user.hasBusiness=false;
                                        this.user.activeBusiness=null;
                                       return  window.location.href="/";

                                    }
                        })
                    }
                }
            },
             closeDeleteBusinessModal() {
              this.showDeleteBusinessModalVisible = false;
                 },

                  showDeleteBusinessModal() {
              this.showDeleteBusinessModalVisible = true;
                 },

        },
    };
</script>