 <template>
  <div>
    <transition name="fade">
      <div id="wrapper-content" class="wrapper-content">
        <div
          v-if="$route.params.partnerId"
          class="page-wrapper d-flex flex-wrap flex-xl-nowrap"
        >
          <div class="page-container" style="height: 85vh; overflow: auto">
            <div class="container-fluid">
              <div v-if="isEditing && !isLoading">
                <EditBusinessStepper
                  v-on:close="canEditBusiness"
                  v-bind:show="canEdit"
                  v-bind:partner-id="$route.params.partnerId"
                />
              </div>
              <div v-else>
                <div class="float-right mb-6 ">
                     <button
                  v-show="!isLoading"
                  type="button"
                  @click="deleteBusiness"
                  class="btn btn-primary font-size-sm ml-3"
                >
                  <i class="fas fa-trash"></i> Delete
                </button>
                &nbsp;
                <button
                  v-show="!isLoading"
                  type="button"
                  @click="canEditBusiness"
                  class="btn btn-darker-light font-size-sm"
                >
                  <i class="fas fa-pencil"></i> Edit
                </button>
                </div>
                <br>
                
                <div class="store-listing-style-04">
                  <BusinessDetail
                  v-bind:trigger-delete-business="triggerDeleteBusiness"
                    v-bind:partner-id="$route.params.partnerId"
                     v-bind:refresh="refresh"
                    v-on:loading="onLoad"
                  />
                </div>

                <div v-show="!isLoading" class="store-listing-style-04">
                  <BusinessDetailTabs
                   v-bind:refresh="refresh"
                    v-bind:partner-id="$route.params.partnerId"
                    v-on:triggereditaddress="triggerEditAddress"
                    v-on:triggeruploadimage="triggerUploadImage"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="map">
            <BusinessMapLocations v-bind:partner-id="$route.params.partnerId" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
 <script>
import BusinessMapLocations from "../MyBusiness/Section/BusinessMapLocations";
import BusinessDetail from "../MyBusiness/Section/BusinessDetail";
import BusinessDetailTabs from "../MyBusiness/BusinessDetailTabs";
import EditBusinessStepper from "../Stepper/EditBusinessStepper";
export default {
  name: "ShowMyBusiness",
  props:['refresh'],
  data: function () {
    return {
      isEditing: false,
      canEdit: "editDetail",
      isLoading: true,
      triggerDeleteBusiness:false
    };
  },
  computed: {},
  components: {
    BusinessMapLocations,
    BusinessDetail,
    BusinessDetailTabs,
    EditBusinessStepper,
  },
  mounted() {}, 
  methods: {
  
    onLoad(loading) {
      this.isLoading = loading;
    },

    canEditBusiness: function () {
      this.isEditing = !this.isEditing;
      this.canEdit = "editDetail";
       this.triggerDeleteBusiness= false;
    },
    deleteBusiness:function(){
      this.triggerDeleteBusiness= !this.triggerDeleteBusiness;
    },

    triggerEditAddress() {
      this.isEditing = !this.isEditing;
      this.canEdit = "editAddress";
      this.triggerDeleteBusiness= false;
    },
    triggerUploadImage() {
      this.isEditing = !this.isEditing;
      this.canEdit = "uploadImage";
      this.triggerDeleteBusiness= false;
    },
  },
};
</script>


 <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>