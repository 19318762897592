<template>
    <div>
        <div v-if="currentBusiness" class="blog-listing blog-listing-grid">


            <ul class="nav nav-pills tab-style-01 text-capitalize font-size-lg text-dark" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="Address-tab" data-toggle="tab" href="#Address" role="tab"
                        aria-controls="Address" aria-selected="true">Address</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="Branches-tab" data-toggle="tab" href="#Branches" role="tab"
                        aria-controls="Branches" aria-selected="false"> Branches </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="Images-tab" data-toggle="tab" href="#Images" role="tab"
                        aria-controls="Images" aria-selected="false">Images</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="Subscription-tab" data-toggle="tab" href="#Subscription" role="tab"
                        aria-controls="Subscription" aria-selected="false">Subscription </a>
                </li>

            </ul>
            <div class="tab-content">
                <div class="tab-pane fade active show" id="Address" role="tabpanel" aria-labelledby="Address">
                    <BusinessAddress   v-bind:refresh="refresh" v-bind:partner-id="partnerId" v-on:triggereditaddress="triggerEditAddress" />
            </div>

        <div class="tab-pane fade" id="Branches" role="tabpanel" aria-labelledby="Branches">
            <ViewBusinessLocations    v-bind:refresh="refresh" v-bind:partner-id="partnerId" />
        </div>
        <div class="tab-pane fade" id="Images" role="tabpanel" aria-labelledby="Images">
           <BusinessImages   v-bind:refresh="refresh" v-bind:partner-id="partnerId" v-on:triggeruploadimage="triggerUploadImage" />
        </div>
        <div class="tab-pane fade" id="Subscription" role="tabpanel" aria-labelledby="Subscription">
          <BusinessSubscription   v-bind:refresh="refresh" v-bind:partner-id="partnerId" />
        </div>

    </div>


    </div>
    </div>
</template>
<script>
    import {
        mapGetters,
    } from "vuex";
    import BusinessAddress from "../MyBusiness/Section/BusinessAddress";
    import BusinessImages from "../MyBusiness/Section/BusinessImages";
    import BusinessSubscription from "../MySubscription/BusinessSubscription";
    import ViewBusinessLocations from "../MyLocations/ViewBusinessLocations"
    export default {
        name: "BusinessDetailTabs",
        props: ["partnerId","refresh"],
        
            components: {
             BusinessAddress,
             BusinessImages,
             BusinessSubscription,
             ViewBusinessLocations
         },
        computed: {
            ...mapGetters("business", ['currentBusiness'])
        },
        mounted() {
            // this.getPartnerBusiness(this.partnerId);
        },
        methods: {
            // ...mapActions("business", ["getPartnerBusiness"]),
            triggerEditAddress(){
                   this.$emit('triggereditaddress',true); 
            },
            triggerUploadImage(){

                this.$emit('triggeruploadimage',true); 
            }

        },
    };
</script>
