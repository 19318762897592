 <template>
  <div>
    <div v-if="partnerId" id="section-accordion">
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            @click="closeEditModal"
            class="btn btn-darker-light mb-6 font-size-sm float-right"
          >
            <i class="fas fa-close"></i> close
          </button>
        </div>
        <div class="col-md-12">
          <div class="accordion mb-6" id="accordionExample">
            <div class="card mb-4">
              <a
                href="#collapseOne"
                class="card-header text-decoration-none collapsed"
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls="collapseOne"
                id="headingOne"
              >
                <span class="card-icon"><i class="fas fa-building"></i></span>
                <span class="card-title mb-0"> My Business </span>
                <span class="dynamic-icon"></span>
              </a>
              <div
                id="collapseOne"
                class="collapse"
                :class="show == 'editDetail' ? 'show' : ''"
                aria-labelledby="headingOne"
                data-parent="#accordionExample"
                style=""
              >
                <div class="card-body">
                  <EditMyBusiness v-bind:partner-id="partnerId" />
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <a
                href="#collapseTwo"
                class="card-header text-decoration-none collapsed"
                id="headingTwo"
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <span class="card-icon"><i class="fas fa-location"></i></span>
                <span class="card-title mb-0"> Business Address </span>
                <span class="dynamic-icon"></span>
              </a>
              <div
                id="collapseTwo"
                class="collapse"
                :class="show == 'editAddress' ? 'show' : ''"
                aria-labelledby="headingTwo"
                data-parent="#accordionExample"
                style=""
              >
                <div class="card-body">
                  <EditMyBusinessAddress v-bind:partner-id="partnerId" />
                </div>
              </div>
            </div>
            <div class="card mb-4">
              <a
                href="#collapseThree"
                class="card-header text-decoration-none collapsed"
                id="headingThree"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <span class="card-icon"><i class="far fa-images"></i></span>
                <span class="card-title mb-0"> Business Images </span>
                <span class="dynamic-icon"></span>
              </a>

              <div
                id="collapseThree"
                class="collapse"
                :class="show == 'uploadImage' ? 'show' : ''"
                aria-labelledby="headingThree"
                data-parent="#accordionExample"
                style=""
              >
                <div class="card-body">
                  <UploadMyBusinessImages
                    v-bind:show-done-btn="false"
                    v-bind:partner-id="partnerId"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import UploadMyBusinessImages from "../Upload/UploadMyBusinessImages";
import EditMyBusiness from "../MyBusiness/EditMyBusiness.vue";
import EditMyBusinessAddress from "../MyBusinessAddress/EditMyBusinessAddress";
export default {
  name: "EditBusinessStepper",
  props: ["partnerId", "show"],
  components: {
    UploadMyBusinessImages,
    EditMyBusiness,
    EditMyBusinessAddress,
  },
  mounted() {},
  methods: {
    closeEditModal: function () {
       return  window.location.href="/myBusiness/"+this.$route.params.partnerId;
    },
  },
};
</script>

