 <template>
  <div>
    <div
      id="googleMap2"
      data-google-map="true"
      class="explore-half-map explore-half-map-list"
      style="height: 100%; position: relative; overflow: hidden"
    >
      <div
        style="
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          background-color: rgb(229, 227, 223);
        "
      >
        <div class="gm-err-container">
          <div class="gm-err-content">
            <div class="gm-err-icon">
              <img
                src="https://maps.gstatic.com/mapfiles/api-3/images/icon_error.png"
                draggable="false"
                style="user-select: none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

 <script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BusinessMapLocations",
  props: ["partnerId","refresh"],
  data: function () {
    return {
      mapProp: {
        center: new window.google.maps.LatLng(40.6812553, -74.0577474),
        zoom: 12,
        mapTypeId: "roadmap",
        disableDefaultUI: true,
        styles: [
          {
            featureType: "administrative.country",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "administrative.province",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "administrative.province",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#c3b6a2",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "labels.text",
            stylers: [
              {
                color: "#c3b6a2",
              },
            ],
          },
          {
            featureType: "landscape.man_made",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#c3b6a2",
              },
            ],
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "landscape.natural.landcover",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "landscape.natural.terrain",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi.business",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.icon",
            stylers: [
              {
                color: "#808080",
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#808080",
              },
            ],
          },
          {
            featureType: "poi.school",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f0f0f0",
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#c0c0c0",
              },
              {
                saturation: -75,
              },
              {
                lightness: -80,
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ededed",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ededed",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text",
            stylers: [
              {
                color: "#ededed",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#91bbd5",
              },
            ],
          },
          {
            featureType: "road.highway.controlled_access",
            stylers: [
              {
                color: "#ededed",
              },
            ],
          },
          {
            featureType: "road.local",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ededed",
              },
            ],
          },
          {
            featureType: "transit.line",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f0f0f0",
              },
            ],
          },
          {
            featureType: "transit.station.airport",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#c3b6a2",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#c7d7d4",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#91bbd5",
              },
            ],
          },
        ],
      },
    };
  },
 watch: {
            refresh:{
                  handler: 'getRefresh'
            }
 },
  computed: {
    ...mapGetters("business", ["currentBusiness"]),
    ...mapGetters("businessMapLocation", ["businessMapLocations"]),
    ...mapGetters(["errors", "isSuccess"]),
  },
  mounted() {
   this.getRefresh();
  },

  methods: {
    ...mapActions("business", ["getPartnerBusiness"]),
    ...mapActions("businessMapLocation", ["getBusinessLocationsCoordinates"]),
  async getRefresh(){
     await this.getBusinessLocationsCoordinates(this.partnerId).then(() => {
      if (this.isSuccess && this.businessMapLocations) {
        this.displayMap(this.businessMapLocations);
      }
    });
  },
    displayMap(businessMapLocations) {
      var initialLat = 47.424;
      var initialLng = 8.487;
      if (businessMapLocations.length > 0) {
        initialLat = parseFloat(
          businessMapLocations[0].gridCodeResult.latitude
        );
        initialLng = parseFloat(
          businessMapLocations[0].gridCodeResult.longitude
        );
      }
      this.mapProp.center = new window.google.maps.LatLng(
        initialLat,
        initialLng
      );

      var map;
      map = new window.google.maps.Map(
        document.getElementById("googleMap2"),
        this.mapProp
      );

      this.$markers = businessMapLocations.map((businessMapLocation) => {
        return {
          position: new window.google.maps.LatLng(
            parseFloat(businessMapLocation.gridCodeResult.latitude),
            parseFloat(businessMapLocation.gridCodeResult.longitude)
          ),
          businessName: businessMapLocation.partner.businessName,
          description: businessMapLocation.partner.description,
          partnerId: businessMapLocation.partner.partnerId,
          partnerImages: businessMapLocation.partner.partnerImages,
          logo: businessMapLocation.partner.logo,
          businessEmail: businessMapLocation.partner.businessEmail,
          website: businessMapLocation.partner.website,
          partnerCategory:
            businessMapLocation.partner.partnerCategory.description,
          partnerAddresses: businessMapLocation.businessAddress,
          icon: "./images/logo-map/pizza.png",
        };
      });

      this.$markers.forEach((marker) => {
        var mapMarker = new window.google.maps.Marker({
          position: marker.position,
          icon: marker.icon,
          map: map,
        });
        var contents = this.infoWindowContents(marker);
        var infowindow = new window.google.maps.InfoWindow({
          content: contents,
        });
        mapMarker.addListener("click", function () {
          infowindow.open(map, mapMarker);
        });
        window.google.maps.event.addListener(map, "click", function () {
          infowindow.close();
        });
        mapMarker.setMap(map);
      });

      //  new MarkerClusterer(this.$map, this.$markers, {
      //     imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      // });
    },

    infoWindowContents(marker) {
 

      return ` <div style="border:1px solid rgb(54, 190, 176);max-height:300px;max-width:400px; overflow-y:auto;overflow-x:hidden">
                  <div >
                    <div>
                    <div class="store-listing-style-04">
                      <div class="" >
                      <div class="d-flex align-items-center flex-wrap flex-md-nowrap ">
                        <div class="row box store media align-items-stretch">

                    
                            <div class="col-md-12">
                            <table style="width:100%">
                              <tr>
                                <td>
                                   <img style="max-height:140px; width:100%" src="${marker.partnerImages.length > 0?marker.partnerImages[0].imageUrl:marker.logo}" class="w-100" />
                                </td>
                              </tr>
                               <tr>
                                  <td>
                                    <div class="p-3 font-size-lg font-weight-semibold text-dark"><span
                                          class="letter-spacing-25"
                                          style="color:#253644">${marker.businessName} | Grid Code:${marker.partnerAddresses.grideCode}</span>
                                      </div>

                                  </td>
                              </tr>
                            </table>
                               
                            </div>
                       
                       
                    </div>


                </div>
              
                </div>
                </div>
                 </div>
                 </div>
                 
`;
    },
  },
};
</script>